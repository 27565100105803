import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootModel } from '../../models/formSchema/root';
import FormTemplateService from '../../services/formTemplate.service';

export const getFormTemplate = createAsyncThunk(
  'formTemplates/get', 
  async ({id}: {id: string}, thunkApi) => {
    try {
      const response = await FormTemplateService.get(id);
      return response.data;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

export interface FormTemplateState {
  loading: boolean;
  error: string | null;
  formTemplate: RootModel | null;
}

const initialState = {
  loading: false,
  error: null,
  formTemplate: null
} as FormTemplateState

const formTemplateSlice = createSlice({
  name: 'formTemplates',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getFormTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFormTemplate.fulfilled, (state, action: PayloadAction<RootModel>) => {
        state.loading = false;
        state.formTemplate = action.payload;
      })
      .addCase(getFormTemplate.rejected, (state, action: PayloadAction<any>) => {
        state.formTemplate = null;
        state.error = action.payload;
      })
      .addDefaultCase((state) => {
        state.formTemplate = null
      });
  },
});

const { reducer } = formTemplateSlice;
export default reducer;