import http from './http-common';
import { RootModel } from '../models/formSchema/root';

class FormTemplateService {
  get(id: string) {
    return http.get<RootModel>(`/FormTemplates/${id}`);
  }

  put(id: string, body: RootModel) {
    return http.put<RootModel>(`/FormTemplates/${id}`, body);
  }

  post(body: RootModel) {
    return http.post<RootModel>('/FormTemplates', body);
  }
}

export default new FormTemplateService();