import { FC } from 'react';
import { Link } from 'react-router-dom';
import { FormListModel } from '../../models/forms/form-list.model';

export type Props = {
  tenantName: string;
  tableId: string;
  page: number;
  totalPages: number;
  setPage: any;
  tableHeaders: Array<string>;
  tableCells: FormListModel[] | null;
};

const FormSearchResultsTable: FC<Props> = ({
  tenantName,
  tableId,
  tableHeaders,
  tableCells,
}: Props) => {
  return (
    <div>
      <table id={tableId} className="govuk-table responsive-table">
        <caption className="govuk-table__caption" />
        <thead className="govuk-table__head">
          <tr className="govuk-table__row">
            {tableHeaders.map((row, idx) => (
              <th key={idx} scope="col" className="govuk-table__header">
                {row}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="govuk-table__body">
          {tableCells?.map((form) => (
            <tr className="govuk-table__row" key={form.schemaId}>
              {form ? (
                <>
                  <td data-th="Form Name" className="govuk-table__cell">
                    {form.schemaName}
                  </td>
                  <td data-th="Form ID" className="govuk-table__cell">
                    {form.schemaId}
                  </td>
                  <td data-th="Actions" className="govuk-table__cell govuk-table__cell--numeric">
                    <Link
                      to={`/${tenantName}/form-management/form-editor/${form.schemaId}`}
                      className="govuk-button search-button"
                    >
                      Edit
                    </Link>
                  </td>
                </>
              ) : (
                <td></td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FormSearchResultsTable;
