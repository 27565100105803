import { Suspense, lazy, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../hooks/useTypedSelector';
import { getAllSchemas } from '../../slices/form/form-management-slice';
import { FormsManagement } from '../../models/forms/form-management.model';
import { FormListModel } from '../../models/forms/form-list.model';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import LoadingSpinner from '../../components/loader/loader';
import SearchCount from '../../components/search/search-count';
import SearchInput from '../../components/search/search-input';
import SearchNoResults from '../../components/search/search-no-results';
import ProtectedRoute from '../../routes/protected-route';
import FormSearchResultsTable from '../../components/search/form-search-results-table';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';

const DashboardLayout = lazy(() => import('../../components/layout/dashboard/dashboard-layout'));

type Props = {
  pageNumber: number;
};

function FormManagement({ pageNumber }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(pageNumber);
  const [formsList, setFormsList] = useState<FormsManagement>();
  const [searchText, setSearchText] = useState<string>('');
  const tenant = useSelector((state: any) => state.tenants.entities);
  const pageSize = 10;

  useEffect(() => {
    dispatch(getAllSchemas({ tenantId: tenant.id, pageSize: pageSize, page: page, searchText: searchText }))
      .unwrap()
      .then((forms) => setFormsList(forms))
      .catch((err) => {
        console.error('Error', err);
        if (tenant.name) {
          const path = `/${tenant.name}/dashboard`;
          navigate(path);
        }
      })
  }, [page, searchText]);

  const handleSearchInput = (searchText: string): void => {
    setSearchText(searchText);
    setPage(1);
  };

  const tableCells: FormListModel[] | null = formsList ? formsList.schemaList : null;
  const tableHeaders = ['Form Name', 'Form ID', ''];

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <DashboardLayout>
        <ProtectedRoute>
          {
            <div id="usersManagement" className="user-management-container govuk-width-container">
              <main className="govuk-main-wrapper main__container" id="main-content" role="main">
                <div className="govuk-grid-row">
                  <h1>Form Management</h1>
                  <SearchInput
                    placeholder="Search"
                    onEnter={handleSearchInput}
                    ariaLabel="Forms Search"
                  ></SearchInput>
                  <SearchCount
                    objectName={'forms'}
                    totalFoundRecords={formsList?.searchResultRecordsCount as number}
                    totalRecords={formsList?.searchResultRecordsCount as number}
                  ></SearchCount>
                  <Link
                    to={`/${tenant.name}/form-management/form-editor/${uuidv4()}`}
                    className="govuk-button search-button"
                    style={{ float: 'right' }}
                  >
                    Create New Form
                  </Link>
                  {(formsList?.searchResultRecordsCount as number) > 0 ? (
                    <FormSearchResultsTable
                      tenantName={tenant.name}
                      tableId="formsTable"
                      page={page}
                      setPage={setPage}
                      totalPages={1}
                      tableHeaders={tableHeaders}
                      tableCells={tableCells}
                    ></FormSearchResultsTable>
                  ) : (
                    <SearchNoResults searchText={searchText} objectName="Form"></SearchNoResults>
                  )}
                </div>
              </main>
            </div>
          }
        </ProtectedRoute>
      </DashboardLayout>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Suspense>
  );
}

export default FormManagement;
