import { FileUploadModel } from '../models/forms/file-upload.model';
import http from './http-file-upload';
import { UploadFileAction } from '../Enums/UploadFileAction';

class FileUploadService {
  post(body: any, formId: string, uploadFileAction: UploadFileAction) {
    const formData = new FormData();
    formData.append('file', body, body.name);
    const uploadFileConfig = {
      headers: {
        'uploadFileAction': uploadFileAction
      }
    };

    return http.post<FileUploadModel>(`/File/upload/${formId}`, formData, uploadFileConfig);
  }
  fileExists(fileName: string, formId: string) {
    return http.get(`/File/exists/${formId}/${fileName}`)
      .then((response) => {
        const serializableData = {
          data: response.data,
          status: response.status,
        };
        return serializableData;
      });
  }
}

export default new FileUploadService();