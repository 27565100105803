import { FormListModel } from '../models/forms/form-list.model';
import { FormsManagement } from '../models/forms/form-management.model';
import http from './http-common';

class FormManagementService {
  getAll(tenantId: string, pageSize: number, page: number, searchText?: string) {
    return http.get<FormsManagement>(`/schema/${tenantId}?pageSize=${pageSize}&page=${page}`, {
      params: {
        searchText: searchText,
      },
    });
  }
  post(body: FormListModel) {
    return http.post<FormListModel>('/schema', body);
  }

  put(id: string, body: FormListModel) {
    return http.put<FormListModel>(`/schema/${id}`, body);
  }
}

export default new FormManagementService();
