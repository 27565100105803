import { FC, useEffect, useState } from 'react';
import SideDrawerMenu from '../select/side-drawer-menu';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/images/header-logo.svg';
import { TenantModel } from '../../models/tenants/tenant-model';
import { getCurrentTenantName } from '../../slices/tenants/tenant-slice';
import './header.scss';

export type Props = {
  tenant: TenantModel
}

const Header: FC<Props> = ({ tenant }: Props) => {
  const { accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const tenantName = getCurrentTenantName();
  const [showAccessLogo, setShowAccessLogo] = useState(true);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 48.0625em)');
    function handleResize() {
      mediaQuery.matches ? setShowAccessLogo(false) : setShowAccessLogo(true);
    }
    mediaQuery.addEventListener('change', handleResize);
    handleResize();
    return () => mediaQuery.removeEventListener('change', handleResize);
  }, [tenant]);

  return(
    <header className="govuk-header govuk-header__container header__nav" data-module="govuk-header">
      <div className={`govuk-width-container ${tenant.tenantLogo.blobName 
        ? 'govuk-header__with-tenant-logo' 
        : 'govuk-header__access-logo'}`
      }>
        {tenant.tenantLogo.blobName && 
          <Link to={isAuthenticated ? `/${tenantName}/dashboard` : `/${tenantName}/signup`}>
            <img 
              src={`${window.REACT_APP_GATEWAY_URI}/tenants/Configuration/${tenant.tenantLogo.blobName}`}
              alt='Your local authority' 
              className='header__nav-logo'
              width='100'
              height='100'
            />
          </Link>
        }
        {(showAccessLogo || !tenant.tenantLogo.blobName) && 
          <Link to={isAuthenticated ? `/${tenantName}/dashboard` : `/${tenantName}/signup`}>
            <Logo 
              title='Access freedom to do more' 
              className='header__nav-logo'
            />
          </Link>
        }
      </div>
      <div className='govuk-width-container govuk-header__content'>
        {isAuthenticated && (
          <SideDrawerMenu 
            accounts={accounts} 
            tenantName={tenantName}
          />
        )}
      </div>
    </header>
  );
}

export default Header;